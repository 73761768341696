import React, { useState } from 'react';
import LandingPage from './components/LandingPage';
import Login from './components/Login';

import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import NewCreation from './components/NewCreation';
import OldProjects from './components/OldProjects';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Adding state to track login status

  return (
    <Router>
      <div className="App">
        {/* You can add a Header component here if you have consistent content across routes */}
        <Routes>
            <Route path="/" element={<LandingPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/dashboard" element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/new-creation" element={<NewCreation />} />
            <Route path="/old-projects" element={<OldProjects />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
