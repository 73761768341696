import React from 'react';
import { useLocation } from 'react-router-dom'; // Importing useLocation
import './LandingPage.css';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/logo.png';
import profileIcon from '../assets/icons/profileicon.svg';


function LandingPage() {
    // Detect if the user is already logged in using location state or other logic you might have set up
    const location = useLocation();
    const isLoggedIn = location.state?.isLoggedIn; // You can adjust this logic based on your setup

    return (
        <div className="App">
            {/* Header */}
            <header className="App-header">
                <div id="logo">
                    <img src={logo} alt="GenXR Logo" />
                    <p id="logo-tagline">Powering Imagination</p>
                </div>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/features">Features</Link></li>
                        <li><Link to="/gallery">Gallery</Link></li>
                        {isLoggedIn ? (
                            <li><img src={profileIcon} alt="Profile Icon" className="profile-icon-img" /></li>

                        ) : (
                            <li><Link to="/login">Login/Register</Link></li>
                        )}
                    </ul>
                </nav>
            </header>

            {/* Hero Section */}
            <section id="hero">
                <h1>Bring Your Imagination to Life</h1>
                <p>Generate stunning 3D visuals from simple text prompts.</p>
                <Link to="/login">
                <button>Get Started</button>
                </Link>
            </section>

            <div className="main-content">

             
             
                
                


           
            {/* How It Works Section */}
            <section id="how-it-works">
                <h2>The Future, Simplified</h2>
                    <p>Dream it, type it, and we craft it in 3D.</p>
                    <p>Powered by Generative AI, accelerating XR development</p>
                    <p>Every word you type takes a step closer to Extended Reality.</p>
            </section>

            {/* Features Section */}
            <section id="features">
                 <h2>Under the Hood</h2>
                    <p>Crystal-clear renders. Pixel-perfect visuals. All in real-time.</p>
            </section>

            {/* Gallery/Showcase */}
            <section id="gallery">
                <h2>Creations Unveiled</h2>
                <div className="image-grid">
                    {/* ... similar structure for each image ... */}
                </div>
            </section>

            {/* Testimonials */}
            <section id="testimonials">
                <h2>From our clients</h2>
                <p>"GenXR is reshaping digital design. It's not just a tool; it's a revolution."</p>
            </section>

            {/* Footer */}
            <footer>
                <div id="footer-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-service">Terms of Service</Link>
                </div>
                <div id="contact-info">
                    <p>Contact us at: qasim@genxr.com</p>
                </div>
                <div id="social-media">
                    {/* ... social media links ... */}
                </div>
            </footer>
            </div>
        </div>
    );
}

export default LandingPage;
