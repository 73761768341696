import React from 'react';

const NewCreation = () => {
    return (
        <div className="content-container">
            <h1>New 3D Creation</h1>
            <p>Here, you can create a new 3D image or environment. This feature will be implemented soon!</p>
        </div>
    );
}

export default NewCreation;
