import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        
        // Replace 'yourPassword' with your actual password
        if (email === 'mqasim.zia92@gmail.com' && password === 'genxr123') {
            navigate('/dashboard');
        } else {
            alert('Invalid email or password');
        }

        // Clear the fields after submitting
        setEmail('');
        setPassword('');
    };

    return (
        <div>
            <h1>Login</h1>
            <p>Login Component</p>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Email:</label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
