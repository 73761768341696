import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

function ThreeDPane() {
    const mountRef = useRef(null);
    const [camera, setCamera] = useState(null);

    useEffect(() => {
        const currentRef = mountRef.current;

        const scene = new THREE.Scene();
        const newCamera = new THREE.PerspectiveCamera(75, currentRef.clientWidth / currentRef.clientHeight, 0.1, 1000);
        setCamera(newCamera);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(currentRef.clientWidth, currentRef.clientHeight);
        currentRef.appendChild(renderer.domElement);

        const geometry = new THREE.BoxGeometry();
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);

        scene.add(cube);

        // Adjusting the camera's position for a top-down view
        newCamera.position.set(0, 5, 5);  // Set the camera position to look from above
        newCamera.lookAt(0, 0, 0);

        const gridHelper = new THREE.GridHelper(100, 50);
        scene.add(gridHelper);

        const axesHelper = new THREE.AxesHelper(50);
        scene.add(axesHelper);

        const controls = new OrbitControls(newCamera, renderer.domElement);
        controls.enableZoom = true;
        controls.update();

        const animate = function () {
            requestAnimationFrame(animate);
            cube.rotation.x += 0.01;
            cube.rotation.y += 0.01;
            renderer.render(scene, newCamera);
        };

        animate();

        function handleResize() {
            const width = currentRef.clientWidth;
            const height = currentRef.clientHeight;

            renderer.setSize(width, height);
            newCamera.aspect = width / height;
            newCamera.updateProjectionMatrix();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            scene.remove(cube);
            geometry.dispose();
            material.dispose();
            renderer.dispose();

            if (currentRef) {
                currentRef.removeChild(renderer.domElement);
            }

            controls.dispose();
        };
    }, [camera]);

    const zoomIn = () => {
        if (camera) {
            camera.position.z -= 1;
        }
    };

    const zoomOut = () => {
        if (camera) {
            camera.position.z += 1;
        }
    };

    return (
        <div ref={mountRef} style={{ width: '100%', height: '100%' }}>
            {/* Zoom Controls */}
            <div style={{ position: 'absolute', right: '10px', top: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <button onClick={zoomIn} style={{ marginBottom: '10px' }}>🔍+</button>
                <button onClick={zoomOut}>🔍-</button>
            </div>
        </div>
    );
}

export default ThreeDPane;
