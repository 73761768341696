import React from 'react';

const OldProjects = () => {
    return (
        <div className="content-container">
            <h1>Old Projects</h1>
            <p>Here, you'll see a list of your previous projects. This feature will be implemented soon!</p>
        </div>
    );
}

export default OldProjects;
