import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importing useNavigate
import './Dashboard.css';
import ThreeDPane from './ThreeDPane';

function Dashboard({ setIsLoggedIn }) { // Receive setIsLoggedIn prop
    const [isMenuOpen, setMenuOpen] = useState(false); // State for the hamburger menu
    const navigate = useNavigate(); // Initialize useNavigate

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    }

    const handleLogout = () => {
        setIsLoggedIn(false); // Set user as logged out
        navigate('/'); // Redirect to landing page
    }

    return (
        <div className="dashboard">
            {/* Top Bar */}
            <div className="top-bar">
                <button className="hamburger" onClick={toggleMenu}>☰</button>
                <button className="btn" onClick={() => navigate('/')}>Home</button> {/* Redirect to landing page */}
                <button className="btn">File</button>
                <button className="btn-beta">Try Beta</button>
                <div className="center-space"></div>
                <button className="btn-pro">Try Pro</button>
                <button className="account-icon">👤</button> {/* This can be replaced with an icon indicating a logged-in user */}
                <button className="share-btn">Share</button>
            </div>
            
            {/* Sliding Menu */}
            <div className={`sliding-menu ${isMenuOpen ? 'active' : ''}`} onMouseLeave={() => setMenuOpen(false)}>
                <div className="top-items">
                    <button onClick={() => setMenuOpen(false)}>New Creations</button>
                    <button onClick={() => setMenuOpen(false)}>Old Projects</button>
                    <button onClick={() => setMenuOpen(false)}>Recent</button>
                </div>

                <div className="bottom-items">
                     <button onClick={() => setMenuOpen(false)}>Settings</button>
                     <button onClick={handleLogout}>Logout</button> {/* Handle logout */}
                </div>
            </div>
            
            {/* 3D View Window */}
            <div className="view-window">
                <ThreeDPane />
            </div>

            {/* Prompt Bar */}
            <div className="prompt-bar prompt-container">
                <select>
                    <option>Environment</option>
                    <option>Object</option>
                    <option>Human</option>
                </select>
                <input type="text" placeholder="Enter your text prompt" />
                <button>Generate</button>
            </div>

        </div>
    );
}

export default Dashboard;
